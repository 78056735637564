import initCalendlyButton from './lib/calendly-button'
import initHotSpots from './lib/hotspots'
import initMarginControl from './lib/margin-control'
import initSegmentTracking from './lib/segment/'
import initSplitTesting from './lib/split-testing'
import initTypeformFunctions from './lib/typeform'
import { urlOverride } from './lib/urlOverride'
import initA11yFunctions from '/lib/accessibility'
import initCopyWebsite from '/lib/copy-website'
import initHeadroom from '/lib/headroom'
import initMobileNav, { initNewNav } from '/lib/mobile-nav'
import initShareButtons from '/lib/share-buttons'
import initSliders from '/lib/sliders'
// Old Site Temporary
import '/lib/child-theme'

initSplitTesting()
initSegmentTracking()
initSliders()
initHeadroom()

if (document.querySelector('.nav-redux')) {
  initNewNav()
} else {
  initMobileNav()
}

initA11yFunctions()
initCalendlyButton()
initCopyWebsite()
initShareButtons()
initHotSpots()
initTypeformFunctions()
initMarginControl()
urlOverride()
