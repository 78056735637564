import $ from 'jquery'

export function urlOverride() {
  $(document).ready(function () {
    // Find all links with 'yardzen.wpenginepowered.com' in the href attribute
    $('a[href*="yardzen.wpenginepowered.com"]').each(function () {
      // Get the current href value
      var currentHref = $(this).attr('href')

      // Replace 'yardzen.wpenginepowered' with 'yardzen.com'
      var updatedHref = currentHref.replace(
        'yardzen.wpenginepowered.com',
        'yardzen.com'
      )

      // Set the updated href value back to the link
      $(this).attr('href', updatedHref)
    })
  })
}
